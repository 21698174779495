import {axiosData} from "@/store/api/axios";

const state = () => ({
  zonas: null || JSON.parse(localStorage.getItem('zonas')),
  zona: null || JSON.parse(localStorage.getItem('zona'))
});

const getters = {
  getZonas(state) {
    return state.zonas;
  },
  getEvento(state) {
    return state.zona;
  },
};

const actions = {
  setEvento({ commit }, payload) {
    commit('SET_ZONA', payload);
    payload
        ? localStorage.setItem('zona', JSON.stringify(payload))
        : localStorage.removeItem('zona');
  },
  setZonas({ commit }, payload) {
    commit('SET_ZONAS', payload);
    payload
        ? localStorage.setItem('zonas', JSON.stringify(payload))
        : localStorage.removeItem('zonas');
  },
  async getZonasNegocioByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'zonas/negocio/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getZonasEventoById(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'zonas/evento/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async deleteZonasById(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'zonas/negocio/'+id,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveZonas(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'zonas/negocio/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }}
};

const mutations = {
  SET_ZONAS(state, payload) {
    state.zonas = payload;
    localStorage.setItem('zonas', JSON.stringify(payload));
  },
  SET_ZONA(state, payload) {
    state.zona = payload;
    localStorage.setItem('zona', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
