import firebase from '@/services/firebase';
import {axiosData} from '../api/axios';
import Cookies from 'js-cookie';

const state = () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
    usuario: JSON.parse(localStorage.getItem('usuario')) || null,
    isPromotor: JSON.parse(localStorage.getItem('ispromotor')) || false,
    tieneNegocio: JSON.parse(localStorage.getItem('tienenegocio')) || false,
    isEvento: JSON.parse(localStorage.getItem('isevento')) || false,
    isSuperAdmin: JSON.parse(localStorage.getItem('issuperadmin')) || false,
    abreviado: JSON.parse(localStorage.getItem('abreviado')) || '',
});

const getters = {
    getUser(state) {
        return state.user ? state.user : null;
    },
    getUsuario(state) {
        return state.usuario ? state.usuario : null;
    },
    getIsPromotor(state) {
        return state.isPromotor ? state.isPromotor : null;
    },
    getIsSuperAdmin(state) {
        return state.isSuperAdmin ? state.isSuperAdmin : null;
    },
    getAbreviado(state) {
        return state.abreviado ? state.abreviado : null;
    },
};

const actions = {
    async LogeaPromotor(){

        const emas={
            email: "promotores@tunay.app",
            password:  "promos2468"
        };

        await this.dispatch('session/loginFirebase',emas);
    },
    async LogeaInvitado(_,payload){
        this.dispatch('ui/handleLoaderBtn', true);

        const emas={
            email: "invitados@tunay.app",
            password:  "invitados2468"
        };

        await this.dispatch('session/loginFirebase',emas);
        this.dispatch('ui/handleLoaderBtn', true);
        return   await this.dispatch('session/registraInvitado',payload);

    },
    async registraInvitado(_,payload){
        try {
            const data = await axiosData(
                true,
                'personas/registrar/invitado',
                payload,
                'post',
                false,
                false
            );
            if(data){
                this.dispatch('ui/handleLoaderBtn', false);
                return data;
            }


        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async loginFirebase(_, payload) {
        this.dispatch('ui/handleLoaderBtn', true);
        try {
            const data = await firebase
                .auth()
                .signInWithEmailAndPassword(payload.email, payload.password);

            if(data){
                const user = data.user;
                const horaTiempo  = await this.dispatch('session/getPeruTime');
                const payLogin ={
                    id: null,
                    uidFirebase: user.uid,
                    email : user.email,
                    nombre: user.displayName,
                    foto :  user.photoURL,
                    createdAt: horaTiempo
                };
                const compr =  await this.dispatch('session/workData',{
                    data: data,
                    persona: payLogin
                });
                return compr;
            }
        } catch (error) {
            if(error.code === "auth/email-already-in-use"){
                await firebase
                    .auth()
                    .sendPasswordResetEmail(this.form.email);
            }
            await this.dispatch('session/muestraErrores',error)
        }
    },
    async createFirebase(_,  payload ) {
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Registrando');

        try {
            const data = await firebase
                .auth()
                .createUserWithEmailAndPassword(payload.data.email, payload.password);

            if (data) {

                const user = data.user;
                const horaTiempo  = await this.dispatch('session/getPeruTime');

                const payLogin ={
                    id: null,
                    uidFirebase: user.uid,
                    email : user.email,
                    nombre: payload.data.nombre,
                    apellidoPaterno: payload.data.apellidoPaterno,
                    foto :  user.photoURL,
                    createdAt: horaTiempo
                };

                const compr =  await this.dispatch('session/workData',{
                    data: data,
                    persona: payLogin
                });
                return compr;
            }
        } catch (error) {
            if(error.code === "auth/email-already-in-use"){
                await firebase
                    .auth()
                    .sendPasswordResetEmail(payload.data.email);
            }
            await this.dispatch('session/muestraErrores',error)
        }
    },
    async muestraErrores(_,error){
        this.dispatch('ui/handleLoaderBtn', false);

        const errorMessages = {
            'auth/invalid-email': 'El formato del email es incorrecto.',
            'auth/user-not-found': 'No existe un usuario asociado a este email.',
            'auth/wrong-password': 'La contraseña es incorrecta.',
            'auth/email-already-exists': 'Su Correo existe en nuestra base de datos de eventos con Google Mail o Apple Login por favor intente con Login con Google o Login con Apple, tambien puede que se le haya enviado un correo para que cambie el password.',
        };

        const errorMessage = errorMessages[error.code] || error.message;
        this.dispatch('ui/setError', {
            code: error.code,
            message: errorMessage,
        });
    },
    async getPeruTime() {

        // Obtener la fecha y hora actual en UTC
        const now = new Date();

        // Convertir a hora de Perú (UTC-5)
        const peruTime = new Date(now.getTime() - (5 * 60 * 60 * 1000));

        // Formatear la fecha en YYYY-MM-DD
        const year = peruTime.getFullYear();
        const month = ('0' + (peruTime.getMonth() + 1)).slice(-2); // Los meses comienzan desde 0
        const day = ('0' + peruTime.getDate()).slice(-2);

        // Formatear la hora en hh:mm:ss
        const hours = ('0' + peruTime.getHours()).slice(-2);
        const minutes = ('0' + peruTime.getMinutes()).slice(-2);
        const seconds = ('0' + peruTime.getSeconds()).slice(-2);

        // Combinar la fecha y la hora en el formato deseado
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    async loginGoogleFirebase(_,provider) {
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Logeando Usuario por Google');

        try {
            firebase.auth().languageCode = 'es'

            const data = await firebase
                .auth()
                .signInWithPopup(provider);

            if (data) {
                const user = data.user;
                const horaTiempo  = await this.dispatch('session/getPeruTime');

                const payLogin ={
                    id: null,
                    uidFirebase: user.uid,
                    email : user.email,
                    nombre: user.displayName,
                    foto :  user.photoURL,
                    createdAt: horaTiempo
                };
                if (payLogin.nombre === ""){
                    payLogin.nombre = "Nombre";
                }
                return await this.dispatch('session/workData', {
                    data: data,
                    persona: payLogin
                });
            }
        } catch (error) {
            await this.dispatch('session/muestraErrores',error)
        }
    },

    async workData(_,work){

        const tokenData = await work.data.user.getIdTokenResult();

        if (tokenData) {

            this.dispatch('session/setUserToken', {
                tokenId: tokenData.token,
                expirationTime: tokenData.expirationTime,
                exp: tokenData.claims.exp,
            });
        }
        const loginData = await this.dispatch('session/compruebaEmail',work.persona);

        if (loginData) {
            return loginData;
        } else {
            this.dispatch('session/logout');
            return false;
        }
    },


    async login(_, user) {
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Logeeando Usuario');

        try {
            const payload = {
                "uuid" : user.uuid,
                "token": user.token
            }
            const data = await axiosData(
                true,
                'personas/loginbyuid',
                payload ,
                'post',
                false,
                false
            );

            if (data.status) return false;

            if (typeof data === 'number') return false;

            if (data.persona.id !== null) {
                this.dispatch('session/setUser', data.persona);
                this.dispatch('session/setUsuario', data.persona);
                this.dispatch('session/setIsPromotor', data.persona.promotor);
                this.dispatch('session/setIsSuperAdmin', data.persona.superAdmin);
                this.dispatch('session/setAbreviado', data.persona.nombre[0] + data.persona.apellidoPaterno[0] );
            }


            if (data){
                this.dispatch('ui/handleLoaderBtn', false);
            }

            return true;
        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },

    async registrar(_,payLogin){
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Registrando Persona');

        try {
            const data = await axiosData(
                true,
                'personas/registrar',
                payLogin,
                'post',
                false,
                false
            );

            if (data) this.dispatch('ui/handleLoaderBtn', false);

            if (typeof data === 'number') return false;
            if (data.persona.id !== null) {
                this.dispatch('session/setUser', data.persona);
                this.dispatch('session/setUsuario', data.persona);
                this.dispatch('session/setIsPromotor', false);
                this.dispatch('session/setIsSuperAdmin', false);
                this.dispatch('session/setAbreviado', data.persona.nombre[0] + data.persona.apellidoPaterno[0] );
            }
            if (payLogin) {
                return payLogin;
            }
        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async getNegociosByPersona(_,id){
        try {
            this.dispatch('ui/handleLoaderBtn', true);
            this.dispatch('ui/setTextLoading', 'Cargando Negocios');

            const data = await axiosData(
                true,
                'personas/negocios/'+id,
                'get',
                false,
                false
            );
            if(Array.isArray(data) && data.length > 0){
                this.dispatch('business/setNegocios',data);
                this.dispatch('business/setNegocio',data[0]);
                this.dispatch('business/setIsNegocio',true);
                this.dispatch('business/setNegocioTipo',data[0].tipoNegocio.id);
            }
            if(data){
                this.dispatch('ui/handleLoaderBtn', false);
                return data;
            }else{
                this.dispatch('ui/handleLoaderBtn', false);
                return null
            }


        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async getClientesByPersona(_,id){
        try {
            this.dispatch('ui/handleLoaderBtn', true);
            this.dispatch('ui/setTextLoading', 'Cargando Clientes');

            const data = await axiosData(
                true,
                'personas/clientes/'+id,
                'get',
                false,
                false
            );

            if(Array.isArray(data) && data.length > 0){
                this.dispatch('clientes/setIsJuegos',data[0].juegos);
                this.dispatch('clientes/setIsEventos',data[0].eventos);
                this.dispatch('clientes/setClientes',data);
                this.dispatch('clientes/setCliente',data[0]);
            }

            if(data){
                this.dispatch('ui/handleLoaderBtn', false);
                return data;
            }else{
                this.dispatch('ui/handleLoaderBtn', false);
                return null
            }


        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async getPermisosByPersona(_,id){
        try {
            this.dispatch('ui/handleLoaderBtn', true);
            this.dispatch('ui/setTextLoading', 'Cargando Permisos');

            const data = await axiosData(
                true,
                'personas/permisos/'+id,
                'get',
                false,
                false
            );

            if(data){
                this.dispatch('business/setPermisos',data);
                this.dispatch('business/setPermiso',data[0]);
                this.dispatch('ui/handleLoaderBtn', false);
                return data;
            }else{
                this.dispatch('ui/handleLoaderBtn', false);
                return null
            }


        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async getPersonaEmail(context,email){
        try {
            this.dispatch('ui/handleLoaderBtn', true);

            const data = await axiosData(
                true,
                'personas/email/'+email,
                'get',
                false,
                false
            );
            if(data){
                this.dispatch('ui/handleLoaderBtn', false);
                return data;
            }


        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async compruebaEmail(_,payload){
        try {
            this.dispatch('ui/handleLoaderBtn', true);

            const data = await axiosData(
                true,
                'personas/email/'+payload.email,
                'get',
                false,
                false
            );
            if (data) this.dispatch('ui/handleLoaderBtn', false);

            if (data.status) return false;

            if (typeof data === 'number') return false;
            if(data.id === null){
                return this.dispatch('session/registrar',payload)
            }else{
                if(data.uidFirebase == null) {
                    data.uidFirebase = payload.uidFirebase
                    return this.dispatch('session/editar',data)
                }else{
                    if(payload.uidFirebase !== data.uidFirebase ){
                        data.uidFirebase = payload.uidFirebase
                        return this.dispatch('session/editar',data)
                    }else{

                        return payload;
                    }
                }
            }
        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async  editar(_,user){
        try {
            this.dispatch('ui/handleLoaderBtn', true);

            const data = await axiosData(
                true,
                'personas/save',
                user,
                'post',
                false,
                false
            );
            if (data) this.dispatch('ui/handleLoaderBtn', false);

            if (data.status) return false;

            if (typeof data === 'number') return false;

            return user;



        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async setPersona(_,payload){
        this.dispatch('ui/handleLoaderBtn', true);
        try {
            const data = await axiosData(
                true,
                'personas/save',
                payload,
                'post',
                false,
                false
            );

            if(data){
                this.dispatch('ui/handleLoaderBtn', false);
                this.dispatch('session/setUsuario', payload);
                this.dispatch('session/setUser', payload);
                return data;
            }
        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Tunay api failed, error: ${error}`);
        }},
    async cambiaPassword(_,payload){
        this.dispatch('ui/handleLoaderBtn', true);
        try {
          let user =  await firebase.auth().currentUser;
            user.updatePassword(payload.password).then(()=>{
               let pay  = {
                   alerta : true,
                   color : "green",
                   mensaje : "Se password se cambio con éxito."
               }
               return pay;
            })
        }catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Tunay api failed, error: ${error}`);
        }
    },
    async logout() {
        this.dispatch('ui/handleLoader', true);
        try {

                await firebase.auth().signOut();
                this.dispatch('ui/handleLoaderBtn', false);
                this.dispatch('session/setUser', null);
                this.dispatch('session/setUsuario', null);
                this.dispatch('session/setUserToken', null);
                this.dispatch('session/setIsPromotor', false);
                this.dispatch('session/setIsSuperAdmin', false);
                this.dispatch('session/setAbreviado', null);
                this.dispatch('business/setNegocios', null);
                this.dispatch('business/setPermisos', null);
                this.dispatch('business/setPermiso', null);
                this.dispatch('business/setNegocio', null);
                this.dispatch('business/setIsNegocio', false);
                this.dispatch('clientes/setClientes',null);
                this.dispatch('clientes/setCliente',null);
                this.dispatch('clientes/setIsEventos', false);
                this.dispatch('clientes/setIsJuegos', false);
                this.dispatch('business/setNegocioTipo', null);
                this.dispatch('business/setNegocioPromotor', null);
                this.dispatch('promotores/setPromotorsLinks', null);
                 this.dispatch('ui/handleLoaderBtn', false);


        } catch (error) {
            this.dispatch('ui/handleLoader', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },

    setUser({ commit }, payload) {
        commit('SET_USER', payload);
        payload
            ? localStorage.setItem('user', JSON.stringify(payload))
            : localStorage.removeItem('user');
    },

    setUsuario({ commit }, payload) {
        commit('SET_USUARIO', payload);
        payload
            ? localStorage.setItem('usuario', JSON.stringify(payload))
            : localStorage.removeItem('usuario');
    },

    setIsPromotor({ commit }, payload) {
        commit('SET_ISPROMOTOR', payload);
        payload
            ? localStorage.setItem('ispromotor', JSON.stringify(payload))
            : localStorage.removeItem('ispromotor');
    },
    setIsSuperAdmin({ commit }, payload) {
        commit('SET_ISSUPERADMIN', payload);
        payload
            ? localStorage.setItem('issuperadmin', JSON.stringify(payload))
            : localStorage.removeItem('issuperadmin');
    },
    setAbreviado({ commit }, payload) {
        commit('SET_ABREVIADO', payload);
        payload
            ? localStorage.setItem('abreviado', JSON.stringify(payload))
            : localStorage.removeItem('abreviado');
    },
    setUserToken(_, payload) {

        if (payload) {
            const  expirationTime  = payload.expirationTime;
            Cookies.set('token', JSON.stringify(payload), {
                expires: new Date(expirationTime),
            });
        } else {
            Cookies.remove('token');
        }
    },

};

const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_USUARIO(state, payload) {
        state.usuario = payload;
    },
    SET_ISPROMOTOR(state, payload) {
        state.isPromotor = payload;
    },
    SET_ISSUPERADMIN(state, payload) {
        state.isSuperAdmin = payload;
    },
    SET_ABREVIADO(state, payload) {
        state.abreviado = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
